import { ReactElement, ReactNode } from "react";
import {
  RiArrowRightLine,
  RiChat1Line,
  RiHazeLine,
  RiMenuSearchLine,
  RiPieChartLine,
  RiSoundModuleLine,
} from "react-icons/ri";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FlexProps,
  HStack,
  Link,
  LinkProps,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";

import { EnvironmentUrl, GlobalSettingsUrl } from "@bucketco/shared/urls";

import OrgSuspendedRibbon from "@/admin/components/OrgSuspendedRibbon";
import { AppEnvironmentPicker } from "@/app/components/AppEnvironmentPicker";
import { useAuthContext } from "@/auth/contexts/authContext";
import FeatureIcon from "@/common/assets/feature-icon.svg?react";
import MotionBox from "@/common/components/MotionBox";
import { NavLink } from "@/common/components/NavLink";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { TrialStatus } from "@/global-settings/components/TrialStatus";

import { AppFooter } from "./AppFooter";

export default function AppSidebar({ ...props }: FlexProps) {
  const { user, currentOrg } = useAuthContext();

  return (
    <Flex
      {...props}
      aria-label="Main"
      as="nav"
      direction="column"
      gap={2.5}
      px={3}
      py={2.5}
    >
      {user?.isAdmin && currentOrg?.suspendedAt !== null && (
        <OrgSuspendedRibbon />
      )}
      <AppEnvironmentPicker />
      <TrackFeature />
      <MainMenu />
      <DemoBanner />
      <Spacer />
      <TrialBanner />
      <HelpLinks />
      <AppFooter />
    </Flex>
  );
}

function TrackFeature() {
  const [searchParams] = useSearchParams();

  return (
    <Button
      as={RouterLink}
      color={useColorModeValue("gray.600", "gray.400")}
      flexShrink={0}
      mx={1}
      size="sm"
      to={{
        pathname: "features/new",
        search: searchParams.toString(),
      }}
      variant="outline"
    >
      New feature
    </Button>
  );
}

function MainMenu() {
  return (
    <Flex
      align="stretch"
      direction="column"
      fontSize="sm"
      gap={0.5}
      justifyContent="start"
    >
      <Divider mb={2} mx={2} w="auto" />
      <MenuHeading icon={<FeatureIcon height={15} width={15} />} to="features">
        Features
      </MenuHeading>

      <MenuHeading icon={<RiPieChartLine size={15} />} to="companies">
        Companies
      </MenuHeading>

      <MenuHeading icon={<RiChat1Line size={15} />} to="feedback">
        Feedback
      </MenuHeading>

      <Divider mx={2} my={2} w="auto" />

      <MenuHeading icon={<RiMenuSearchLine size={15} />} to="event-log">
        Event log
      </MenuHeading>

      <MenuHeading icon={<RiSoundModuleLine size={15} />} to="settings">
        Settings
      </MenuHeading>
    </Flex>
  );
}

function MenuHeading({
  children,
  icon,
  to,
}: {
  children: ReactNode;
  icon?: ReactElement;
  manageButton?: ReactElement;
  to?: string;
}) {
  const activeBg = useColorModeValue("gray.100", "gray.800");
  const hoverText = useColorModeValue("gray.900", "gray.50");
  const text = useColorModeValue("gray.600", "gray.400");

  return (
    <Flex
      _hover={{ color: hoverText }}
      align="center"
      as={NavLink}
      borderRadius="md"
      color={text}
      end={false}
      gap={1.5}
      position="relative"
      px={2}
      py={1.5}
      sx={{
        '&[aria-current="page"]': {
          bg: activeBg,
          color: hoverText,
        },
      }}
      to={to}
      transition="all 0.1s ease-in-out"
    >
      <Box flex="0 0 auto">{icon}</Box>
      <Box
        flex="1 1 auto"
        fontWeight="medium"
        overflow="hidden"
        whiteSpace="nowrap"
        data-menu-heading
        isTruncated
      >
        {children}
      </Box>
    </Flex>
  );
}

export function FooterLink({ children, ...props }: LinkProps) {
  return (
    <Link
      {...props}
      _hover={{ color: useColorModeValue("gray.900", "white") }}
      color="dimmed"
      fontSize="sm"
      fontWeight="medium"
      px={2}
      textDecoration="none"
    >
      {children}
    </Link>
  );
}

function DemoBanner() {
  const { currentOrg, currentApp } = useAuthContext();
  const hoverColor = useColorModeValue("gray.900", "white");
  const titleColor = useColorModeValue("blue.600", "blue.300");
  const borderColor = useColorModeValue("gray.200", "gray.650");

  if (!currentApp?.demo) return null;

  const nonDemoApp = currentOrg?.apps.find((app) => !app.demo);
  const nonDemoEnv = nonDemoApp?.environments.find((env) => env.isProduction);

  if (!nonDemoEnv) return null;

  return (
    <AnimatePresence>
      <MotionBox
        animate={{ translateY: 0, opacity: 1 }}
        initial={{ translateY: 10, opacity: 0 }}
        transition={{ type: "spring", delay: "0.150" }}
      >
        <Link
          _hover={{ color: hoverColor }}
          as={RouterLink}
          color="dimmed"
          display="block"
          mb={1}
          mx={1}
          textDecoration="none"
          to={EnvironmentUrl(nonDemoEnv)}
          variant="unstyled"
        >
          <Card
            borderColor={borderColor}
            color="inherit"
            fontSize="sm"
            gap={1}
            px={3}
            py={2.5}
          >
            <HStack color={titleColor} spacing={1}>
              <RiHazeLine size={18} />
              <Text fontSize="md" fontWeight="medium" textDecoration="none">
                Demo mode
              </Text>
            </HStack>
            <HStack>
              <Text fontWeight="medium">Switch to live app</Text>
              <RiArrowRightLine size={14} />
            </HStack>
          </Card>
        </Link>
      </MotionBox>
    </AnimatePresence>
  );
}

function TrialBanner() {
  const hoverColor = useColorModeValue("gray.900", "white");

  const { currentEnv, currentOrg } = useAuthContext();

  if (
    currentEnv === undefined ||
    currentOrg === undefined ||
    currentOrg.trialEndsAt === null
  ) {
    return null;
  }

  return (
    <>
      <Link
        _hover={{ color: hoverColor }}
        as={RouterLink}
        color="dimmed"
        fontWeight="medium"
        px={2}
        py={1.5}
        textDecoration="none"
        to={GlobalSettingsUrl(currentEnv, "org-billing")}
      >
        <TrialStatus />
      </Link>
      <Divider mb={1} mt={0} mx={2} w="auto" />
    </>
  );
}

function HelpLinks() {
  const { requestFeedback } = useFeature("global");

  return (
    <Flex align="flex-start" direction="column" gap={1}>
      <FooterLink
        px={2}
        textDecoration="none"
        onClick={(e) => {
          e.preventDefault();
          requestFeedback({
            title: "How do you like Bucket?",
            position: {
              type: "POPOVER",
              anchor: e.currentTarget,
            },
            openWithCommentVisible: true,
          });
        }}
      >
        Give feedback
      </FooterLink>

      <FooterLink
        href="mailto:hello@bucket.co"
        px={2}
        textDecoration="none"
        onClick={(e) => {
          if (
            "HubSpotConversations" in window &&
            typeof window.HubSpotConversations?.widget?.open === "function"
          ) {
            e.preventDefault();
            window.HubSpotConversations.widget.open();
          }
        }}
      >
        Chat with us
      </FooterLink>

      <FooterLink
        href="https://bucket.co/changelog"
        px={2}
        target="_blank"
        textDecoration="none"
      >
        Changelog
      </FooterLink>
      <FooterLink
        href="https://docs.bucket.co"
        px={2}
        target="_blank"
        textDecoration="none"
      >
        Documentation
      </FooterLink>
    </Flex>
  );
}
