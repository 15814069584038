import React from "react";
import { Link as RouterLink, useSearchParams } from "react-router-dom";
import { Button, Flex } from "@chakra-ui/react";

import { CompanyAutocompleteSelect } from "@/common/components/Autocomplete/CompanyAutocompleteSelect";
import { FeatureAutocompleteSelect } from "@/common/components/Autocomplete/FeatureAutocompleteSelect";
import { SatisfactionFilter } from "@/common/components/SatisfactionFilter";
import { SegmentPicker } from "@/common/components/SegmentPicker";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { StarsFunnelFilter } from "@/feature/components/StarsFunnelFilter";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

export const FeedbackFilters = ({
  canCreate,
  canFilterBySegment = true,
  canFilterByFeature = true,
  canFilterByCompany = true,
}: {
  canCreate?: boolean;
  canFilterBySegment?: boolean;
  canFilterByFeature?: boolean;
  canFilterByCompany?: boolean;
}) => {
  const [searchParams] = useSearchParams();
  const {
    satisfactionFilter,
    onSatisfactionFilterChange,
    featureFilter,
    onFeatureFilterChange,
    companyFilter,
    onCompanyFilterChange,
    funnelStepsFilter,
    onFunnelStepsFilterChange,
    segmentFilter,
    onSegmentFilterChange,
  } = useFeedbackTableState();

  return (
    <Flex align="center" gap={2} wrap="wrap">
      {canCreate && (
        <Button
          as={RouterLink}
          size="sm"
          to={{
            pathname: "new",
            search: searchParams.toString(),
          }}
          variant="primary"
        >
          New feedback
        </Button>
      )}
      <SatisfactionFilter
        size="sm"
        value={satisfactionFilter}
        onChange={(value) => {
          onSatisfactionFilterChange(value);
          segmentAnalytics.track("Feedbacks Filter Updated", {
            type: "satisfaction",
            value: satisfactionFilter,
          });
        }}
      />
      {canFilterByFeature && (
        <FeatureAutocompleteSelect
          placeholder="Filter by feature"
          value={featureFilter}
          canClear
          valueAsId
          onChange={(featureId) => {
            onFeatureFilterChange(featureId);
            segmentAnalytics.track("Feedbacks Filter Updated", {
              type: "featureFilter",
              value: featureId,
            });
          }}
        />
      )}
      {canFilterByCompany && (
        <CompanyAutocompleteSelect
          placeholder="Filter by company"
          value={companyFilter}
          canClear
          valueAsId
          onChange={(companyId) => {
            onCompanyFilterChange(companyId);
            segmentAnalytics.track("Feedbacks Filter Updated", {
              type: "companyFilter",
              value: companyId,
            });
          }}
        />
      )}
      <StarsFunnelFilter
        disabledSteps={["segment"]}
        value={funnelStepsFilter}
        onChange={(newSteps) => {
          onFunnelStepsFilterChange(newSteps);
          segmentAnalytics.track("Feedbacks Filter Updated", {
            type: "funnelSteps",
            value: funnelStepsFilter,
          });
        }}
      />
      {canFilterBySegment && (
        <SegmentPicker
          includeAll={false}
          placeholder="Filter by segment"
          value={segmentFilter}
          canClear
          onChange={(segmentId) => {
            onSegmentFilterChange(segmentId);
            segmentAnalytics.track("Feedbacks Filter Updated", {
              type: "segmentFilter",
              value: segmentId,
            });
          }}
        />
      )}
    </Flex>
  );
};
