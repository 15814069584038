import { Box, useColorModeValue, useToken } from "@chakra-ui/react";

export function BoxySpinner() {
  const colorVal = useColorModeValue("brand.500", "brand.300");
  const color = useToken("colors", colorVal);

  return (
    <Box __css={{ "--box-color": color }} className="boxy-spinner">
      <Box className="box1"></Box>
      <Box className="box2"></Box>
      <Box className="box3"></Box>
    </Box>
  );
}
