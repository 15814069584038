import { Center, Spinner } from "@chakra-ui/react";

import {
  BucketProvider,
  useFeature as useBucketFeature,
} from "@bucketco/react-sdk";
import { AvailableFeatures } from "@bucketco/shared/features";

import { useAuthContext } from "@/auth/contexts/authContext";
import { getAllowTracking } from "@/common/utils/analytics/blockNonMembertracking";
import { API_URL, BUCKET_PUBLISHABLE_KEY } from "@/common/utils/env";

declare module "@bucketco/react-sdk" {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface Features extends AvailableFeatures {}
}

export function FeatureFlagsProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { user, currentOrg } = useAuthContext();

  const userCtx = {
    id: user?.id,
    email: user?.email,
    name: user?.name,
  };

  const companyCtx = {
    id: currentOrg?.id,
    access_level: currentOrg?.accessLevel,
    created_at: currentOrg?.createdAt,
  };

  // skip rendering the Bucket component if the tracking key is not set
  // or we're not logged in/bootstrapping
  if (!BUCKET_PUBLISHABLE_KEY || !user?.id) {
    return children;
  }

  return (
    <BucketProvider
      apiBaseUrl={`${API_URL}/ingest-proxy`}
      company={companyCtx}
      enableTracking={getAllowTracking()}
      loadingComponent={
        <Center h="100vh">
          <Spinner size="sm" />
        </Center>
      }
      publishableKey={BUCKET_PUBLISHABLE_KEY}
      staleWhileRevalidate={false}
      toolbar={user?.isAdmin}
      user={userCtx}
    >
      {children}
    </BucketProvider>
  );
}

export function useFeature(key: keyof AvailableFeatures) {
  return useBucketFeature(key);
}
