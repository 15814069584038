import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Center,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
} from "@chakra-ui/react";

import { useSearchParam } from "@/common/hooks/useSearchParam";
import { EnvironmentDisplayName } from "@/environment/components/EnvironmentDisplayName";
import { useEnvironmentData } from "@/environment/data/useEnvironmentData";
import { useFeatureData } from "@/feature/data/useFeatureData";
import FlagVersions from "@/flags/components/FlagVersions";

export default function FeatureTargetingVersions() {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const [searchParams] = useSearchParams();
  const [envId] = useSearchParam("environment");
  const featureQuery = useFeatureData(featureId!);
  const environmentQuery = useEnvironmentData(envId);

  return (
    <Modal
      size="6xl"
      isOpen
      onClose={() => {
        navigate({
          pathname: "..",
          search: searchParams.toString(),
        });
      }}
    >
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <HStack>
            <Heading>Targeting versions</Heading>
            {environmentQuery.isSuccess && (
              <HStack fontSize="sm" spacing={1}>
                <Text color="dimmed" fontWeight="normal">
                  for
                </Text>
                <EnvironmentDisplayName environment={environmentQuery.data} />
              </HStack>
            )}
          </HStack>
        </ModalHeader>

        {featureQuery.isLoading || environmentQuery.isLoading ? (
          <ModalBody minH="lg">
            <Center flexGrow={1} minH="lg">
              <Spinner color="dimmed" size="sm" />
            </Center>
          </ModalBody>
        ) : featureQuery.isSuccess && environmentQuery.isSuccess ? (
          <ModalBody>
            <FlagVersions
              environmentId={environmentQuery.data.id}
              featureId={featureId!}
              flagId={featureQuery.data.flagId!}
            />
          </ModalBody>
        ) : (
          <ModalBody>
            <Center flexGrow={1}>
              <Text color="dimmed" fontSize="sm">
                An error occurred while loading the feature access versions.
                Please try reloading the page.
              </Text>
            </Center>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}
