import { useEffect } from "react";
import { useResolvedPath } from "react-router-dom";

import OldDebugger from "@/app/pages/DebuggerRecent";
import DebuggerRevamped from "@/app/pages/DebuggerRevamped";
import HeaderLayout from "@/common/components/HeaderLayout";
import RouterTabs, {
  Route,
  useActivePathRouteIndex,
} from "@/common/components/RouterTabs";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export default function Tracking() {
  const tabsRoutes: Route[] = [
    {
      route: useResolvedPath("recent").pathname,
      name: "Recent",
    },
    {
      route: useResolvedPath("distinct").pathname,
      name: "Distinct",
    },
  ];
  const activeRouteIndex = useActivePathRouteIndex(tabsRoutes);

  useEffect(() => {
    segmentAnalytics.page("Tracking");
  }, []);

  return (
    <HeaderLayout title="Event log" borderless>
      <RouterTabs
        activeRouteIndex={activeRouteIndex}
        routes={tabsRoutes}
        tabListProps={{ px: 2 }}
        titleTemplate="Event log › %s"
      />
    </HeaderLayout>
  );
}

export function DebuggerEventsWrapper() {
  const { isEnabled } = useFeature("revamped-debugger");
  return isEnabled ? <DebuggerRevamped /> : <OldDebugger />;
}
