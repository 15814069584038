import { z } from "zod";

import { ExternalIdSchema } from "./schemas/external";
import { APIResponse } from "./api";
import { AttributeField } from "./attributeFilter";
import { AttributeValuesQuerySchema } from "./companyAPI";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";

export interface Event {
  name: string;
  attributes: { [key: string]: string };
  timestamp: string;
  user:
    | {
        id: string;
        name: string | null;
        email: string | null;
        avatarUrl: string | null;
        companies: {
          id: string;
          name: string | null;
          avatarUrl: string | null;
        }[];
      }
    | undefined;
}

export const DebugEventTypes = [
  "check",
  "evaluate",
  "check-config",
  "evaluate-config",
  "track",
  "feedback",
  "user",
  "company",
  "feedback",
] as const;

export type DebugEventType = (typeof DebugEventTypes)[number];

export interface DebugEvent {
  id: string;
  envId: string;
  timestamp: string;
  sdkVersion: string;
  serviceName: string;
  user?: {
    id: string;
    name?: string;
    email?: string;
    avatarUrl?: string;
    known: boolean;
  };
  company?: {
    id: string;
    name?: string;
    avatarUrl?: string;
    known: boolean;
    inferred: boolean;
  };
  debugEventType: DebugEventType;
  featureFlagEvent?: {
    featureKey: string;
    targetingVersion: number;
    evalResult: boolean;
    evalConfig?: {
      key: string;
      payload: any;
    };
    evalRuleResults: boolean[];
    evalContext: Record<string, string>;
    evalMissingFields: string[];
  };
  name?: string;
  attributes?: Record<string, string>;
  feedback?: {
    featureId: string;
    score?: number;
    comment?: string;
    question?: string;
    promptedQuestion?: string;
    promptId?: string;
    source?: string;
  };
  features: {
    id: string;
    name: string;
  }[];
}

export const LatestEventsQuerySchema = EnvironmentSelectionQuerySchema.extend({
  lastTS: z.string().optional(),
  eventName: z.string().optional(),
});

export const DebugEventsQuerySchema = EnvironmentSelectionQuerySchema.extend({
  lastTS: z.string().optional(),
  eventName: z.string().optional(),
  featureId: z.string().optional(),
  companyId: ExternalIdSchema.optional(),
  userId: ExternalIdSchema.optional(),
  eventTypes: z.array(z.enum(DebugEventTypes)).optional(),
});

export type LatestEventsQueryType = z.input<typeof LatestEventsQuerySchema>;
export type DebugEventsQueryType = z.input<typeof DebugEventsQuerySchema>;

export const EventAttributeValuesQuerySchema =
  EnvironmentSelectionQuerySchema.merge(AttributeValuesQuerySchema);

type EventAttributeValuesQueryType = z.input<
  typeof EventAttributeValuesQuerySchema
>;

export interface EventAPI {
  "/apps/:appId/events/latest": {
    GET: {
      response: APIResponse<{
        events: Event[];
      }>;
      params: { appId: string };
      query: LatestEventsQueryType;
    };
  };
  "/apps/:appId/debug-events/latest": {
    GET: {
      response: APIResponse<{
        events: DebugEvent[];
      }>;
      params: { appId: string };
      query: DebugEventsQueryType;
    };
  };
  "/apps/:appId/events/by-name": {
    GET: {
      response: APIResponse<{
        events: string[];
      }>;
      params: { appId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/events/by-name/:eventName/attributes": {
    GET: {
      response: APIResponse<{
        attributes: AttributeField[];
      }>;
      params: { appId: string; eventName: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/events/by-name/:eventName/attributes/:attribute/values": {
    GET: {
      response: APIResponse<{
        values: string[];
      }>;
      params: { appId: string; eventName: string; attribute: string };
      query: EventAttributeValuesQueryType;
    };
  };
}
