import { QueryClient, useQuery, useQueryClient } from "@tanstack/react-query";

import { BootstrapDTO } from "@bucketco/shared/bootstrapAPI";

import { featureViewQueryKeys } from "@/app/data/featureViewQueryKeys";
import { setDatadogUser } from "@/app/utils/datadog";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import { setAllowTracking } from "@/common/utils/analytics/blockNonMembertracking";
import api from "@/common/utils/api";
import { BUCKET_ORG_ID, ENVIRONMENT } from "@/common/utils/env";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { featureQueryKeys } from "@/feature/data/featureQueryKeys";

export function useBootstrapData(
  orgId?: string,
  envId?: string,
  enabled?: boolean,
) {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: commonQueryKeys.bootstrap,

    queryFn: () =>
      api
        .get<"/bootstrap">("/bootstrap", {
          params: {
            envId: envId,
            orgId: orgId,
          },
        })
        .then((res) => res.data)
        .then((data) => bootstrapLoadingSideEffects(data, queryClient)),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: Infinity,
    enabled: !!enabled,
  });
}

function bootstrapLoadingSideEffects(
  bootstrapData: BootstrapDTO,
  queryClient: QueryClient,
): BootstrapDTO {
  const isBucketUser = bootstrapData.user.email.endsWith("@bucket.co");

  // We don't want to risk tracking any bucket employee activity
  // on accounts that are not Bucket's own account
  if (
    ENVIRONMENT === "production" &&
    isBucketUser &&
    bootstrapData.org?.id !== BUCKET_ORG_ID
  ) {
    setAllowTracking(false);
  } else {
    setAllowTracking(true);
  }

  const { id: userId, name, email, avatarUrl } = bootstrapData.user;
  // Pass name, email and avatar in case a frontend segment
  // destination needs them to generate personalized UI
  segmentAnalytics.identify(userId, {
    name,
    email,
    avatar: avatarUrl,
  });

  setDatadogUser({ id: userId, name, email });

  if (bootstrapData.org) {
    segmentAnalytics.group(bootstrapData.org.id, {
      name: bootstrapData.org.name,
    });
  }

  // set the feature views in the query cache
  for (const [appId, data] of Object.entries(bootstrapData.featureViews)) {
    queryClient.setQueryData(featureViewQueryKeys.list(appId), data);
  }

  // Set the feature names in the query cache
  for (const [appId, data] of Object.entries(bootstrapData.featureNames)) {
    queryClient.setQueryData(featureQueryKeys.listNames(appId), data);
  }

  return bootstrapData;
}
