import { useEffect } from "react";
import { useParams, useResolvedPath, useSearchParams } from "react-router-dom";
import { Box, Spinner } from "@chakra-ui/react";

import CompanyDisplay from "@/common/components/CompanyDisplay";
import HeaderLayout from "@/common/components/HeaderLayout";
import RouterTabs, {
  Route,
  useActivePathRouteIndex,
} from "@/common/components/RouterTabs";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useCompanyData } from "@/company/data/useCompanyData";

const tabSafeParams = (params: URLSearchParams) => {
  params.forEach((_, key) => {
    // Remove any non-filtering params (such as sorting, columns, page, etc.)
    if (!["search", "stars", "satisfaction", "feature"].includes(key)) {
      params.delete(key);
    }
  });
  return params.toString();
};

export default function Company() {
  const [searchParams] = useSearchParams();
  const { companyId } = useParams();
  const safeParams = tabSafeParams(searchParams);

  const { data: company, isLoading, isSuccess } = useCompanyData(companyId);

  useEffect(() => {
    segmentAnalytics.page("Company");
  }, []);

  const tabsRoutes: Route[] = [
    {
      name: "Features",
      route: {
        pathname: useResolvedPath("features").pathname,
        search: safeParams,
      },
    },
    {
      name: "Feedback",
      count: company?.feedbackCount,
      countIsLoading: isLoading,
      route: {
        pathname: useResolvedPath("feedback").pathname,
        search: safeParams,
      },
    },
    {
      name: "Users",
      count: company?.userCount,
      countIsLoading: isLoading,
      route: {
        pathname: useResolvedPath("users").pathname,
        search: safeParams,
      },
    },
    {
      name: "Attributes",
      route: {
        pathname: useResolvedPath("attributes").pathname,
        search: safeParams,
      },
    },
  ];

  const activeRouteIndex = useActivePathRouteIndex(tabsRoutes);

  return (
    <HeaderLayout
      title={
        isLoading ? (
          <Box minH={6}>
            <Spinner size="sm" />
          </Box>
        ) : isSuccess ? (
          <CompanyDisplay
            as="h1"
            company={company}
            fontSize={"lg"}
            fontWeight="medium"
          />
        ) : undefined
      }
      borderless
    >
      <RouterTabs
        activeRouteIndex={activeRouteIndex}
        routes={tabsRoutes}
        tabListProps={{ px: 2 }}
        titleTemplate={`Company › ${company?.name} › %s`}
      />
    </HeaderLayout>
  );
}
