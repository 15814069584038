import { FormEventHandler, useMemo } from "react";
import { useFormContext } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { Flex } from "@chakra-ui/react";

import { CreateFeatureArgsType } from "@bucketco/shared/featureAPI";
import { KeyFormatPatterns } from "@bucketco/shared/utils/keyFormat";

import { useAuthContext } from "@/auth/contexts/authContext";
import { FormFieldSet } from "@/common/components/Form/FormFieldSet";
import FormInput from "@/common/components/Form/FormInput";

type Props = {
  id?: string;
  onSubmit?: FormEventHandler<HTMLFormElement>;
};

const placeholderName = "Export to CSV";

export const NewFeatureForm = ({ id = "new-feature", onSubmit }: Props) => {
  const { currentOrg } = useAuthContext();
  const { setValue } = useFormContext<CreateFeatureArgsType>();
  const [params] = useSearchParams();

  useMemo(() => {
    setValue("name", params.get("name") || "", { shouldDirty: true });
    setValue("key", params.get("key") || "", { shouldDirty: true });
  }, [params, setValue]);

  const actualFeatureKeyFormat = useMemo(() => {
    return currentOrg!.featureKeyFormat === "custom"
      ? "kebabCaseLower"
      : currentOrg!.featureKeyFormat;
  }, [currentOrg]);

  const placeholderKey = useMemo(() => {
    return KeyFormatPatterns[actualFeatureKeyFormat].transform(placeholderName);
  }, [actualFeatureKeyFormat]);

  return (
    <form id={id} onSubmit={onSubmit}>
      <Flex direction="column" gap={4}>
        <FormFieldSet>
          <FormInput
            autoComplete="off"
            label="Name"
            name="name"
            placeholder={placeholderName}
            data-1p-ignore
            onChange={(e) => {
              setValue(
                "key",
                KeyFormatPatterns[actualFeatureKeyFormat].transform(
                  e.currentTarget.value,
                ),
                { shouldDirty: true, shouldValidate: true },
              );
            }}
          />

          <FormInput
            description={
              "The feature key is used to check access, track usage and gather feedback. Cannot be changed."
            }
            descriptionInPortal={false}
            label="Key"
            name="key"
            placeholder={placeholderKey}
          />
        </FormFieldSet>
      </Flex>
    </form>
  );
};
