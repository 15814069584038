import React from "react";
import { Grid, Text, useColorModeValue } from "@chakra-ui/react";
import { ErrorMessage } from "@hookform/error-message";

import { FlagVersionTargeting } from "@bucketco/shared/flagAPI";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import {
  RuleSimpleCompanySelector,
  RuleSimpleSegmentSelector,
  RuleSimpleUserSelector,
} from "@/common/components/Rule/RuleSimpleSelectors";

const sharedGridProps = {
  gap: 2,
  templateColumns: "80px auto",
};

const sharedLabelProps = {
  fontSize: "sm",
  lineHeight: 8,
};

type RuleSimpleFormProps = {
  baseName: string;
  selectedUsers?: Set<string>;
  selectedCompanies?: Set<string>;
  selectedSegments?: Set<string>;
};

export function RuleSimpleForm({
  baseName,
  selectedUsers,
  selectedCompanies,
  selectedSegments,
}: RuleSimpleFormProps) {
  const errorColor = useColorModeValue("red.500", "red.300");

  return (
    <Grid as="fieldset" {...sharedGridProps}>
      <Text {...sharedLabelProps}>Segments</Text>
      <ManagedFormControl
        name={`${baseName}.segmentIds`}
        render={({ field }) => (
          <RuleSimpleSegmentSelector {...field} blocklist={selectedSegments} />
        )}
        rules={{
          deps: [baseName],
        }}
      />

      <Text {...sharedLabelProps}>Companies</Text>
      <ManagedFormControl
        name={`${baseName}.companyIds`}
        render={({ field }) => (
          <RuleSimpleCompanySelector {...field} blocklist={selectedCompanies} />
        )}
        rules={{
          deps: [baseName],
        }}
      />

      <Text {...sharedLabelProps}>Users</Text>
      <ManagedFormControl
        name={`${baseName}.userIds`}
        render={({ field }) => (
          <RuleSimpleUserSelector {...field} blocklist={selectedUsers} />
        )}
        rules={{
          deps: [baseName],
        }}
      />

      <ErrorMessage
        name={`${baseName}.root`}
        render={({ message }) => (
          <Text color={errorColor} fontSize="sm" gridColumn="span 2">
            {message}
          </Text>
        )}
      />
    </Grid>
  );
}

type RuleSimpleDisplayProps = {
  targeting: FlagVersionTargeting;
};

export const RuleSimpleDisplay = ({ targeting }: RuleSimpleDisplayProps) => {
  const { segmentIds, companyIds, userIds } = targeting;

  return (
    <Grid {...sharedGridProps}>
      {!!segmentIds.length && (
        <>
          <Text {...sharedLabelProps}>Segments</Text>
          <RuleSimpleSegmentSelector value={segmentIds} isReadOnly />
        </>
      )}

      {!!companyIds.length && (
        <>
          <Text {...sharedLabelProps}>Companies</Text>
          <RuleSimpleCompanySelector value={companyIds} isReadOnly />
        </>
      )}

      {!!userIds.length && (
        <>
          <Text {...sharedLabelProps}>Users</Text>
          <RuleSimpleUserSelector value={userIds} isReadOnly />
        </>
      )}
    </Grid>
  );
};
