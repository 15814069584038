import { forwardRef } from "react";
import { Textarea, TextareaProps } from "@chakra-ui/react";

import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import {
  FormElementBaseProps,
  useManagedFormControlProps,
} from "@/common/hooks/useManagedFormControlProps";
import { mergeFunctions } from "@/common/utils/mergeFunctions";
import { mergeRefs } from "@/common/utils/mergeRefs";
import { FooterNote, FooterNoteProps } from "../FooterNote";

export type FormTextAreaProps = {
  footer?: FooterNoteProps;
} & FormElementBaseProps &
  Omit<TextareaProps, "value">;

const FormTextArea = forwardRef<HTMLTextAreaElement, FormTextAreaProps>(
  ({ _control, onChange, onBlur, footer, ...rest }, ref) => {
    const [managedProps, inputProps] = useManagedFormControlProps(rest);

    return (
      <ManagedFormControl
        {..._control}
        {...managedProps}
        description={footer ? <FooterNote {...footer} /> : undefined}
        render={({ field }) => (
          <Textarea
            {...inputProps}
            {...field}
            ref={mergeRefs(field.ref, ref)}
            onBlur={mergeFunctions(field.onBlur, onBlur)}
            onChange={mergeFunctions(field.onChange, onChange)}
          />
        )}
      />
    );
  },
);

FormTextArea.displayName = "FormTextArea";
export default FormTextArea;
