import { useState } from "react";
import {
  RiArrowLeftRightLine,
  RiLogoutBoxLine,
  RiUserLine,
} from "react-icons/ri";
import {
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  useColorModeValue,
} from "@chakra-ui/react";
import { useClerk } from "@clerk/clerk-react";

import { useAuthContext } from "@/auth/contexts/authContext";
import OrganizationSwitcher from "@/common/components/OrganizationSwitcher";
import { UserDisplay } from "@/common/components/UserDisplay";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";

export function AppProfileMenu() {
  const { openUserProfile } = useClerk();
  const { user } = useAuthContext();
  const { signOut } = useAuthContext();
  const textColor = useColorModeValue("gray.900", "white");
  const [isOrgSwitcherOpen, setIsOrgSwitcherOpen] = useState(false);

  if (!user) return null;

  return (
    <>
      <Menu placement="top-start" size="sm">
        <MenuButton
          _hover={{ color: textColor }}
          as={Button}
          color="dimmed"
          variant="unstyled"
        >
          <Flex align="center" flexDirection="row" gap={2}>
            <UserDisplay
              _avatar={{ size: "xs" }}
              fontSize="sm"
              fontWeight="medium"
              user={user}
              isTruncated
              onlyFirstName
            />
          </Flex>
        </MenuButton>
        <Portal>
          <MenuList minW="auto" ml={-2}>
            <MenuItem
              fontWeight="medium"
              icon={<RiUserLine size={14} />}
              iconSpacing={1.5}
              onClick={() => {
                openUserProfile();
              }}
            >
              Account
            </MenuItem>
            <MenuItem
              fontWeight="medium"
              icon={<RiArrowLeftRightLine size={14} />}
              iconSpacing={1.5}
              onClick={(e) => {
                e.preventDefault();
                setIsOrgSwitcherOpen(true);
              }}
            >
              Switch organization
            </MenuItem>
            <MenuItem
              fontWeight="medium"
              icon={<RiLogoutBoxLine size={14} />}
              iconSpacing={1.5}
              onClick={async (e) => {
                e.preventDefault();
                segmentAnalytics.reset();

                await signOut();
              }}
            >
              Log out
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <OrganizationSwitcher
        isOpen={isOrgSwitcherOpen}
        onClose={() => setIsOrgSwitcherOpen(false)}
      />
    </>
  );
}
