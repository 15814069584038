import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { ButtonGroup, Flex, IconButton, Spinner, Text } from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";

import { getFraction } from "@bucketco/shared/utils/getFraction";

import CompactNumber from "@/common/components/CompactNumber";
import { formatNumber } from "@/common/utils/numberFormatting";

export default function DataTablePagination<TData>({
  isLoading,
  pageSize,
  pageIndex,
  totalCount,
  nextPage,
  getCanNextPage,
  previousPage,
  getCanPreviousPage,
}: Table<TData> & {
  pageSize: number;
  pageIndex: number;
  totalCount: number;
  isLoading?: boolean;
}) {
  const pageCount = Math.ceil(getFraction(totalCount, pageSize));

  const hasPageInfo =
    pageCount !== undefined &&
    pageIndex !== undefined &&
    pageSize !== undefined &&
    totalCount !== undefined;

  if (isLoading) {
    return <Spinner color="dimmed" mr={2} size="xs" />;
  }

  return (
    <Flex align="center" as="nav">
      <Text color="dimmed" fontSize="sm" mr={2} whiteSpace="nowrap">
        {hasPageInfo ? (
          pageCount > 1 ? (
            <>
              {`${formatNumber(
                Math.min(pageIndex * pageSize + 1, totalCount),
              )} to ${formatNumber(
                Math.min(pageSize * pageIndex + pageSize, totalCount),
              )} of `}
              <CompactNumber value={totalCount} />
            </>
          ) : (
            <>
              <CompactNumber value={totalCount} /> of{" "}
              <CompactNumber value={totalCount} />
            </>
          )
        ) : null}
      </Text>
      {totalCount > pageSize && (
        <ButtonGroup size="sm" spacing={0} variant="ghost">
          <IconButton
            aria-label="Previous page"
            icon={<RiArrowLeftSLine size={18} />}
            isDisabled={!getCanPreviousPage()}
            onClick={previousPage}
          />
          <IconButton
            aria-label="Next page"
            icon={<RiArrowRightSLine size={18} />}
            isDisabled={!getCanNextPage()}
            onClick={nextPage}
          />
        </ButtonGroup>
      )}
    </Flex>
  );
}
