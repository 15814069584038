import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";
import { Heading, Text, VStack } from "@chakra-ui/react";

import { FeaturesUrl } from "@bucketco/shared/urls";

import { useAppCreateMutation } from "@/app/data/useAppCreateMutation";
import { useOrgsData } from "@/app/data/useOrgsData";
import { BoxySpinner } from "@/common/components/BoxySpinner";
import CenteredLayout from "@/common/components/CenteredLayout";
import { useErrorToast } from "@/common/hooks/useErrorToast";
import { useFeature } from "@/common/hooks/useFeatureFlags";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";

export default function GenerateDemoApp() {
  const { orgId } = useParams();
  const errorToast = useErrorToast();
  const navigate = useNavigate();
  const { data, isLoading } = useOrgsData();
  const orgs = data?.orgs;

  const demoApp = orgs
    ?.find((org) => org.apps.find((app) => app.demo))
    ?.apps.find((app) => app.demo);

  const features = useFeatureNamesData(
    {},
    { appId: demoApp?.id, retry: true, enabled: !!demoApp?.id },
  );
  const demoAppHasFeatures = features.data?.length && features.data?.length > 0;
  const { track } = useFeature("demo-app-in-onboarding");

  const [creatingDemoApp, setCreatingDemoApp] = useState(false);

  const createDemoApp = useAppCreateMutation({
    onSuccess: (app) => {
      // we just created a new app which doesn't exist in the bootstrap
      // data yet, so we do a full page reload to get the new data here.
      window.location.assign(FeaturesUrl(app.environments[0]));
    },
    onError: () => {
      errorToast({
        title: "Could not create app",
      });
      navigate("/");
    },
  });

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!demoApp?.id && !creatingDemoApp) {
      // start creating
      setCreatingDemoApp(true);
      track();
      createDemoApp.mutate({
        name: "B2B demo app",
        organizationId: orgId!,
        demoData: true,
      });
      return;
    }

    // demo app already exists, is it ready to redirect to?
    if (demoApp?.id && demoAppHasFeatures) {
      navigate(FeaturesUrl(demoApp.environments[0]));
      return;
    }

    // demo app exist, but it's not ready yet
    // pretend like we're creating it
    setCreatingDemoApp(true);
  }, [
    createDemoApp,
    orgId,
    track,
    isLoading,
    demoApp?.id,
    demoApp?.environments,
    demoAppHasFeatures,
    setCreatingDemoApp,
    creatingDemoApp,
    navigate,
  ]);

  return (
    <CenteredLayout showBucketLogo>
      <Helmet>
        <title>Get Started</title>
      </Helmet>
      <VStack spacing={6}>
        <BoxySpinner />
        {!creatingDemoApp && (
          <VStack spacing={3}>
            <Heading fontSize="2xl">Hang tight!</Heading>
            <Text color="dimmed">Generating a demo app for you.</Text>
          </VStack>
        )}
      </VStack>
    </CenteredLayout>
  );
}
