import { z } from "zod";

import { APIResponse } from "./api";

// Skip image processing the following domains
export const skipImageProcessing = [
  "https://img.clerk.com",
  "https://upcdn.io/kW15ayo",
];

// Schema for validating image URLs
export const ImageUrlSchema = z.string().url("Invalid image URL");
export type ImageUrl = z.infer<typeof ImageUrlSchema>;

// API
export interface ImageAPI {
  "/images/avatars/:url": {
    GET: {
      response: APIResponse<Buffer>;
      params: { url: string };
      query: { hash: string };
    };
  };
}
