import { Badge, Tooltip } from "@chakra-ui/react";

export function EvaluationCell({ value }: { value?: boolean | null }) {
  let icon: React.ReactNode;
  let description: string;

  if (value) {
    description =
      "Based on the current access rules, this feature is enabled for the company";
    icon = (
      <Badge colorScheme="green" rounded="full" variant="outline">
        Yes
      </Badge>
    );
  } else {
    description =
      "Based on the current access rules, this feature is disabled for the company";
    icon = (
      <Badge colorScheme="gray" rounded="full" variant="outline">
        No
      </Badge>
    );
  }

  return (
    <Tooltip
      label={description}
      openDelay={300}
      placement="start-start"
      hasArrow
    >
      {icon}
    </Tooltip>
  );
}
