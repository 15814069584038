import { Link } from "react-router-dom";
import { Flex, StackProps, Text } from "@chakra-ui/react";

import { CompanyName } from "@bucketco/shared/companyAPI";
import { CompanyUrl } from "@bucketco/shared/urls";
import { OptionalKeys } from "@bucketco/shared/utils/types";

import { useAuthContext } from "@/auth/contexts/authContext";
import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";
import { Avatar } from "@/common/components/Avatar";

type Props = StackProps & {
  company: OptionalKeys<CompanyName, "name" | "avatarUrl">;
  link?: boolean;
  search?: string;
  showId?: boolean;
};

export default function CompanyDisplay({ company, link, ...rest }: Props) {
  const { currentEnv } = useAuthContext();

  if (link) {
    return (
      <Text as={Link} to={CompanyUrl(currentEnv!, company.id)}>
        <CompanyDisplayInner company={company} {...rest} />
      </Text>
    );
  }

  return <CompanyDisplayInner company={company} {...rest} />;
}

function CompanyDisplayInner({
  company,
  search,
  showId = false,
  ...rest
}: Props) {
  const name = company.name || company.id;
  const avatarUrl = company.avatarUrl;
  return (
    <Flex alignItems="center" gap={2} whiteSpace="nowrap" {...rest}>
      <Avatar borderRadius={4} name={name} src={avatarUrl ?? undefined} />
      <Text>{search ? highlightStringMatch(name, search) : name}</Text>
      {company.name && showId ? (
        <Text color="dimmedDisabled">
          ({search ? highlightStringMatch(company.id, search) : company.id})
        </Text>
      ) : null}
    </Flex>
  );
}
