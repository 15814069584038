import "prism-react-editor/prism/languages/json";

import { useRef } from "react";
import { RiFileCopyLine, RiSortDesc } from "react-icons/ri";
import {
  Box,
  ButtonGroup,
  chakra,
  Flex,
  IconButton,
  Text,
  useToast,
} from "@chakra-ui/react";
import { Editor, PrismEditor } from "prism-react-editor";
import {
  useDefaultCommands,
  useEditHistory,
} from "prism-react-editor/commands";
import { useCursorPosition } from "prism-react-editor/cursor";
import { IndentGuides } from "prism-react-editor/guides";
import { useHightlightBracketPairs } from "prism-react-editor/highlight-brackets";
import { useBracketMatcher } from "prism-react-editor/match-brackets";
import { useTagMatcher } from "prism-react-editor/match-tags";
import {
  useHighlightSelectionMatches,
  useSearchWidget,
} from "prism-react-editor/search";

import formatSvg from "@/common/assets/indent-list.svg?react";
import { useBucketCodeTheme } from "@/common/components/CodeEditor/useBucketCodeTheme";
import { useErrorToast } from "@/common/hooks/useErrorToast";

import "prism-react-editor/layout.css";
// Required by the basic setup
import "prism-react-editor/search.css";

const FormatIcon = chakra(formatSvg);

const sortObjectKeys = (obj: any): any => {
  if (obj !== null && typeof obj === "object" && !Array.isArray(obj)) {
    const sorted = Object.keys(obj)
      .sort()
      .reduce((acc: any, key: string) => {
        acc[key] = sortObjectKeys(obj[key]);
        return acc;
      }, {});
    return sorted;
  }
  return obj;
};

function ExtendedEditor({ editor }: { editor: PrismEditor }) {
  useBracketMatcher(editor);
  useHightlightBracketPairs(editor);
  useTagMatcher(editor);
  useDefaultCommands(editor);
  useEditHistory(editor);
  useSearchWidget(editor);
  useHighlightSelectionMatches(editor);
  useCursorPosition(editor);

  return <IndentGuides editor={editor} />;
}

interface JsonEditorProps {
  initialValue?: string | unknown[] | Record<string, unknown> | null;
  label?: string;
  isReadOnly?: boolean;
  onChange?: (value: string) => void;
}

export const JsonEditor: React.FC<JsonEditorProps> = ({
  initialValue,
  label,
  isReadOnly = false,
  onChange,
}) => {
  const theme = useBucketCodeTheme();
  const editorRef = useRef<PrismEditor | null>(null);
  const toast = useToast();
  const errorToast = useErrorToast();

  // Make initial value immutable
  const value = useRef<string>(
    typeof initialValue !== "string" && initialValue !== null
      ? JSON.stringify(initialValue, null, 2)
      : initialValue ?? "",
  );

  const handleChange = (value: string) => {
    try {
      onChange?.(JSON.parse(value));
    } catch {
      onChange?.(value);
    }
  };

  const handleFormat = () => {
    if (!editorRef.current?.textarea) return;
    try {
      const parsed = JSON.parse(editorRef.current.value);
      const formatted = JSON.stringify(parsed, null, 2);
      editorRef.current.textarea.value = formatted;
      editorRef.current.update();
      handleChange(formatted);
      toast({
        title: `${label || "JSON"} formatted`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch {
      errorToast({
        description: "Can only format valid JSON",
      });
    }
  };

  const handleSort = () => {
    if (!editorRef.current?.textarea) return;
    try {
      const parsed = JSON.parse(editorRef.current.value);
      const sorted = JSON.stringify(sortObjectKeys(parsed), null, 2);
      editorRef.current.textarea.value = sorted;
      editorRef.current.update();
      handleChange(sorted);
      toast({
        title: `${label || "JSON"} sorted`,
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    } catch {
      errorToast({
        description: "Can only sort valid JSON",
      });
    }
  };

  const handleCopy = () => {
    if (!editorRef.current?.textarea) return;
    navigator.clipboard.writeText(editorRef.current.value);
    toast({
      title: `${label || "JSON"} copied`,
      status: "success",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Box sx={theme} w="full">
      <Flex align="center" gap={2} mb={1}>
        {label && (
          <Text as="label" fontWeight="medium" htmlFor="jsonEditor">
            {label}
          </Text>
        )}
        <ButtonGroup
          justifyContent="flex-end"
          ml="auto"
          size="xs"
          spacing={1}
          variant="outline"
        >
          <IconButton
            aria-label="Format"
            icon={<FormatIcon boxSize={4} />}
            onClick={handleFormat}
          />
          <IconButton
            aria-label="sort"
            icon={<RiSortDesc size={16} />}
            onClick={handleSort}
          />
          <IconButton
            aria-label="Copy"
            icon={<RiFileCopyLine size={16} />}
            onClick={handleCopy}
          />
        </ButtonGroup>
      </Flex>
      <Editor
        ref={editorRef}
        language="json"
        readOnly={isReadOnly}
        textareaProps={
          {
            id: "jsonEditor",
            "data-enable-grammarly": false,
            "data-gramm": false,
            "data-gramm_editor": false,
          } as any
        }
        value={value.current}
        onUpdate={handleChange}
      >
        {(editor) => <ExtendedEditor editor={editor} />}
      </Editor>
    </Box>
  );
};
