import { RefinementCtx, z } from "zod";

import { ExternalIdSchema } from "./schemas/external";
import { SatisfactionScore } from "./schemas/satisfactionScore";
import { APIEmptyResponse, APIResponse } from "./api";
import {
  EnvironmentSelectionQuerySchema,
  EnvironmentSelectionQueryType,
} from "./environmentAPI";
import {
  EventSelectorSchema,
  EventSelectorType,
  SubsegmentQuerySchema,
  UseTargetingRulesSchema,
} from "./featureAPI";

export type FeatureFeedbackHistogram = {
  total: number;
  noScore: number;
} & Record<Exclude<SatisfactionScore, 0>, number>;

export type FeatureFeedbackCampaignDTO = {
  id: string;
  createdAt: string;
  updatedAt: string;
  enabled: boolean;
  question: string;
  allowRepeatFeedback: boolean;
  minimumInteractions: number;
  promptMinSecondsAfterUse: number;
  promptMaxSecondsAfterUse: number;
  eventSelector: EventSelectorType | null;
};

export type SendPromptDTO = {
  promptId: string;
};

export type UserFeedbackPromptingStatusDTO = {
  status: "online" | "offline";
};

export type FeedbackPromptingStatusDTO = {
  configured: boolean;
  lastSeen: string | null;
};

export const FeedbackPromptStatisticsQuerySchema =
  EnvironmentSelectionQuerySchema.extend({
    featureId: z.string().optional(),
    startDate: z.coerce.date(),
    endDate: z.coerce.date(),
  }).strict();

export type FeedbackPromptStatisticsQueryType = z.input<
  typeof FeedbackPromptStatisticsQuerySchema
>;

export interface FeedbackPromptStatisticsDTO {
  counts: {
    total: number;
    dismissed: number;
    replied: number;
    other: number;
  };
  periods: {
    periodStart: string;
    totalCount: number;
    dismissedCount: number;
    repliedCount: number;
    otherCount: number;
  }[];
}

export const BaseFeatureFeedbackCampaignArgsSchema = z.object({
  eventSelector: EventSelectorSchema.nullable().default(null),
  question: z.string().nonempty("Prompt question must be set").max(255),
  allowRepeatFeedback: z.boolean().default(false),
  minimumInteractions: z
    .number({ invalid_type_error: "Must be a whole number" })
    .int()
    .min(1)
    .default(1),
  promptMinSecondsAfterUse: z
    .number({ invalid_type_error: "Must be a whole number" })
    .int()
    .min(1)
    .default(1),
  promptMaxSecondsAfterUse: z
    .number({ invalid_type_error: "Must be a whole number" })
    .int()
    .min(1)
    .default(10),
  enabled: z.boolean().default(false),
});

function refinePromptTimeRange(
  schema: {
    promptMinSecondsAfterUse?: number;
    promptMaxSecondsAfterUse?: number;
  },
  ctx: RefinementCtx,
) {
  if (schema.promptMinSecondsAfterUse === undefined) return false;
  if (schema.promptMaxSecondsAfterUse === undefined) return false;

  if (schema.promptMinSecondsAfterUse >= schema.promptMaxSecondsAfterUse) {
    ctx.addIssue({
      code: z.ZodIssueCode.too_big,
      message: "Min. time must be less than max. time",
      maximum: schema.promptMaxSecondsAfterUse,
      inclusive: false,
      type: "number",
      path: ["promptMinSecondsAfterUse"],
    });

    ctx.addIssue({
      code: z.ZodIssueCode.too_small,
      message: "Max. time must be more than min. time",
      minimum: schema.promptMinSecondsAfterUse,
      inclusive: false,
      type: "number",
      path: ["promptMaxSecondsAfterUse"],
    });
  }
}

export const CreateFeatureFeedbackCampaignArgsSchema =
  BaseFeatureFeedbackCampaignArgsSchema.strict().superRefine(
    refinePromptTimeRange,
  );

export const PatchFeatureFeedbackCampaignArgsSchema =
  BaseFeatureFeedbackCampaignArgsSchema.strict()
    .partial()
    .superRefine(refinePromptTimeRange);

export const SendPromptArgsSchema = z
  .object({
    userId: ExternalIdSchema,
    question: z.string().max(255).optional(),
  })
  .strict();

export type CreateFeatureFeedbackCampaignArgsType = z.input<
  typeof CreateFeatureFeedbackCampaignArgsSchema
>;

export type PatchFeatureFeedbackCampaignArgsType = z.input<
  typeof PatchFeatureFeedbackCampaignArgsSchema
>;

export const DEFAULT_FEEDBACK_CAMPAIGN_PROPERTIES = {
  enabled: false,
  question: "",
  promptMinSecondsAfterUse: 1,
  promptMaxSecondsAfterUse: 10,
  allowRepeatFeedback: false,
  minimumInteractions: 1,
  eventSelector: null,
} as const satisfies CreateFeatureFeedbackCampaignArgsType;

export type SendPromptArgs = z.infer<typeof SendPromptArgsSchema>;

export const FeatureFeedbackHistogramQuerySchema =
  EnvironmentSelectionQuerySchema.merge(UseTargetingRulesSchema)
    .merge(SubsegmentQuerySchema)
    .strict();

export type FeatureFeedbackHistogramQueryType = z.input<
  typeof FeatureFeedbackHistogramQuerySchema
>;

export interface FeatureFeedbackAPI {
  "/apps/:appId/features/:featureId/feedback/histogram": {
    GET: {
      response: APIResponse<FeatureFeedbackHistogram>;
      params: { appId: string; featureId: string };
      query: FeatureFeedbackHistogramQueryType;
    };
  };
  "/apps/:appId/features/:featureId/feedback/campaigns": {
    GET: {
      response: APIResponse<FeatureFeedbackCampaignDTO[]>;
      params: { appId: string; featureId: string };
      query: EnvironmentSelectionQueryType;
    };
    POST: {
      body: CreateFeatureFeedbackCampaignArgsType;
      response: APIResponse<FeatureFeedbackCampaignDTO>;
      params: { appId: string; featureId: string };
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/features/:featureId/feedback/campaigns/:campaignId": {
    GET: {
      response: APIResponse<FeatureFeedbackCampaignDTO>;
      params: { appId: string; featureId: string; campaignId: string };
    };
    DELETE: {
      response: APIEmptyResponse;
      params: { appId: string; featureId: string; campaignId: string };
    };
    PATCH: {
      body: PatchFeatureFeedbackCampaignArgsType;
      response: APIResponse<FeatureFeedbackCampaignDTO>;
      params: { appId: string; featureId: string; campaignId: string };
    };
  };
  "/apps/:appId/features/:featureId/feedback/prompt": {
    POST: {
      params: { appId: string; featureId: string };
      response: APIResponse<SendPromptDTO>;
      body: SendPromptArgs;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/users/:userId/status": {
    GET: {
      params: { appId: string; userId: string };
      response: APIResponse<UserFeedbackPromptingStatusDTO>;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/feedback-prompting-status": {
    GET: {
      params: { appId: string };
      response: APIResponse<FeedbackPromptingStatusDTO>;
      query: EnvironmentSelectionQueryType;
    };
  };
  "/apps/:appId/feedback-prompting-statistics": {
    GET: {
      params: { appId: string };
      query: FeedbackPromptStatisticsQueryType;
      response: APIResponse<FeedbackPromptStatisticsDTO>;
    };
  };
}
