import dayjs from "dayjs";
import { z } from "zod";

export function nullablePartial<TSchema extends z.AnyZodObject>(
  schema: TSchema,
) {
  const entries = Object.entries(schema.shape) as [
    keyof TSchema["shape"],
    z.ZodTypeAny,
  ][];

  const newProps = entries.reduce(
    (acc, [key, value]) => {
      acc[key] = value.nullable().optional();
      return acc;
    },
    {} as {
      [key in keyof TSchema["shape"]]: z.ZodOptional<
        z.ZodNullable<TSchema["shape"][key]>
      >;
    },
  );

  return z.object(newProps);
}

export type NullablePartial<T> = {
  [K in keyof T]: T[K] | null;
};

type PermitsUndefined<T> = T extends undefined ? true : false;

export type ExplicitlyNullable<T> = T extends Record<string, unknown>
  ? {
      [K in keyof T]: ExplicitlyNullable<T[K]>;
    }
  : PermitsUndefined<T> extends true
  ? Exclude<T, undefined> | null
  : T;

export function explicitlyNullable<TObject extends Record<string, unknown>>(
  object: TObject,
) {
  return Object.fromEntries(
    Object.entries(object).map(([key, value]) => [key, value ?? null]),
  ) as ExplicitlyNullable<TObject>;
}

export type NonEmpty<T extends any[]> = [T[number], ...T[number][]];

export type NonOptional<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Required<Pick<T, K>> & Omit<T, K>;

export type OptionalKeys<
  T extends Record<string, unknown>,
  K extends keyof T,
> = Omit<T, K> & Partial<Pick<T, K>>;

export type DistributivePick<T, K extends keyof T> = T extends unknown
  ? Pick<T, K>
  : never;

export type DistributiveOmit<T, K extends keyof T> = T extends unknown
  ? Omit<T, K>
  : never;

/**
 * Utility type to recursively generate paths for all properties in an object.
 * @template T - The object type.
 * @returns A union type of all path properties.
 * @example
 * // Usage
 * type MyObject = {
 *   foo: {
 *     bar: string;
 *   };
 *   baz: number;
 * };
 *
 * type MyPaths = ObjectPaths<MyObject>;
 * // MyPaths will be equal to:
 * // "foo" | "baz" | "foo.bar"
 */
export type Paths<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${"" | `.${Paths<T[K]>}`}`;
    }[keyof T]
  : never;

/**
 * Utility type to recursively generate leaf path for all properties in an object.
 * @template T - The object type to extract leaf properties from.
 * @returns A union type of all leaf properties.
 * @example
 * // Usage
 * type MyObject = {
 *   foo: {
 *     bar: string;
 *   };
 *   baz: number;
 * };
 *
 * type MyLeaves = Leaves<MyObject>;
 * // MyLeaves will be equal to:
 * // "baz" | "foo.bar"
 */
export type Leaves<T> = T extends object
  ? {
      [K in keyof T]: `${Exclude<K, symbol>}${Leaves<T[K]> extends never
        ? ""
        : `.${Leaves<T[K]>}`}`;
    }[keyof T]
  : never;

export type AllOrNone<T> = T | { [K in keyof T]?: never };

type InferableType = "string" | "number" | "boolean" | "date";

const inferableDateTimeFormats = [
  "YYYY-MM-DDTHH:mm:ss.SSS[Z]",
  "YYYY-MM-DDTHH:mm:ss.SSS",
  "YYYY-MM-DDTHH:mm:ss",
  "YYYY-MM-DD",
];

function isDateOrEpoch(value: string) {
  const number = Number(value);
  if (!isNaN(number)) {
    if (value.length === 13 || value.length === 10) {
      return dayjs(number).isValid();
    }
  } /* if (value.length === 10 || value.length >= 19) */ else {
    return dayjs(value, inferableDateTimeFormats).isValid();
  }

  return false;
}

function coerceType(
  assumed: InferableType | undefined,
  potential: InferableType | undefined,
) {
  if (!assumed) {
    return potential;
  }

  if (!potential) {
    return assumed;
  }

  if (assumed !== potential) {
    return "string";
  }

  return assumed;
}

function inferValueType(value: string) {
  if (!value?.length || value === "null") {
    return undefined;
  } else if (isDateOrEpoch(value)) {
    return "date";
  } else if (!isNaN(Number(value))) {
    return "number";
  } else if (value == "true" || value == "false") {
    return "boolean";
  } else {
    return "string";
  }
}

/**
 * Infers the type of a value based on its content.
 *
 * @param values - The values to infer the type from.
 * @returns The inferred type.
 */
export function inferType(...values: string[]) {
  return (
    values.reduce(
      (type, item) => {
        return coerceType(type, inferValueType(item));
      },
      undefined as InferableType | undefined,
    ) ?? "string"
  );
}
