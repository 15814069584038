import React, { useMemo } from "react";
import { Flex, FlexProps, Spinner, Text, TextProps } from "@chakra-ui/react";

import {
  compileRules,
  filterFilters,
  getFilterCount,
} from "@bucketco/shared/filter";
import { FlagRule, FlagVersionTargeting } from "@bucketco/shared/flagAPI";
import { filterMap } from "@bucketco/shared/utils/array";
import { NonEmpty } from "@bucketco/shared/utils/types";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useEstimateTargetAudience } from "@/common/data/useEstimateTargetAudience";
import pluralize from "@/common/utils/pluralize";
import useAllSegment from "@/company/data/useAllSegment";

function getRulesFilterCount(rules: FlagRule[]): number {
  return rules.reduce(
    (acc, { filter }) => (filter ? acc + getFilterCount(filter) : acc),
    0,
  );
}

const sharedEstimationTextProps = {
  as: "span",
  color: "dimmed",
  fontSize: "sm",
  lineHeight: "21px",
} satisfies TextProps;

const rulesRemovalText =
  "Only segments, company attributes, and feature access will be used for company count estimations.";

type Props = FlexProps & {
  environmentId: string;
  targeting: FlagVersionTargeting;
};

export const RulesEstimation = ({
  environmentId,
  targeting,
  ...rest
}: Props) => {
  const { targetingMode } = targeting;
  const allCount = useAllSegment()?.allCount ?? 0;

  const [rules, rulesRemoved] = useMemo(() => {
    const rules = compileRules(targeting);
    const ruleCount = getRulesFilterCount(rules);
    const filteredRules = filterMap(rules, (rule) => {
      // Keep only filters that can be used for company estimations
      const filter = filterFilters(rule.filter, [
        "segment",
        "companyAttribute",
        "featureTargeting",
      ]);
      return filter ? { ...rule, filter } : undefined;
    });
    // If we removed any filters, we need to inform the user
    const filteredCount = getRulesFilterCount(filteredRules);
    return [filteredRules, ruleCount !== filteredCount];
  }, [targeting]);

  const canEstimate = targetingMode === "some" && !!rules.length;

  const {
    data: estimate,
    isLoading: isLoadingEstimate,
    isSuccess: isSuccessEstimate,
  } = useEstimateTargetAudience(
    {
      envIds: [environmentId],
      ruleGroups: [rules as NonEmpty<FlagRule[]>],
    },
    {
      enabled: canEstimate,
      select: (data) => data[environmentId]?.[0] ?? 0,
    },
  );

  return (
    <Flex {...rest}>
      {targetingMode === "everyone" ? (
        <Text {...sharedEstimationTextProps}>
          {allCount} {pluralize("company", allCount, "companies")}
        </Text>
      ) : isLoadingEstimate ? (
        <Spinner color="dimmed" size="sm" />
      ) : isSuccessEstimate && canEstimate ? (
        <Flex align="center" direction="row" gap={1}>
          <Text {...sharedEstimationTextProps}>
            ~ {estimate} {pluralize("company", estimate, "companies")}
          </Text>
          {rulesRemoved && (
            <InfoIconTooltip renderInPortal={false} text={rulesRemovalText} />
          )}
        </Flex>
      ) : !canEstimate && rulesRemoved ? (
        <Flex align="center" direction="row" gap={1}>
          <Text {...sharedEstimationTextProps}>No estimation</Text>
          {rulesRemoved && (
            <InfoIconTooltip renderInPortal={false} text={rulesRemovalText} />
          )}
        </Flex>
      ) : (
        <Text {...sharedEstimationTextProps}>0 companies</Text>
      )}
    </Flex>
  );
};
