import React, { useMemo } from "react";
import { RiBracesLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { Box, HStack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";

import {
  CompaniesColumn,
  CompaniesSortBy,
  CompanyList,
  CompanyListItem,
} from "@bucketco/shared/companyAPI";
import { FeatureName } from "@bucketco/shared/featureAPI";
import { SegmentDTO } from "@bucketco/shared/segmentAPI";
import { FeatureCompanyUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import DataCell from "@/common/components/DataCell";
import { DataTable } from "@/common/components/DataTable/DataTable";
import { FrequencyProgress } from "@/common/components/FrequencyProgress";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { featureMetricOptions } from "@/common/components/Rule/RuleMetric";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import { linkClickModifierHandler } from "@/common/utils/linkClickHandler";
import { AddColumnPopover } from "@/company/components/AddColumnPopover";
import CompaniesEmptyState from "@/company/components/CompaniesEmptyState";
import { SegmentFilters } from "@/company/components/SegmentFilters";
import { useCompaniesTableState } from "@/company/hooks/useCompaniesTableState";
import { FeatureDisplay } from "@/feature/components/FeatureDisplay";
import { useFeatureNamesData } from "@/feature/data/useFeatureNamesData";
import { useStarsFunnelColors } from "@/feature/hooks/useStarsFunnelColors";
import { FeedbackSatisfactionIcon } from "@/feedback/components/FeedbackSatisfaction";

export const defaultCompanyColumns: CompaniesColumn[] = [
  "name",
  "firstSeen",
  "lastSeen",
  "feedbackCount",
  "userCount",
];

const MetricHeader = ({
  feature,
  metric,
}: {
  feature: FeatureName;
  metric: string;
}) => {
  const metricOption = featureMetricOptions.find(
    (option) => option.metric === metric,
  );
  return (
    <Tooltip
      label={`${feature.name} • ${metricOption?.label}`}
      openDelay={300}
      hasArrow
    >
      <HStack spacing={1.5}>
        <FeatureDisplay
          feature={feature}
          flexShrink={1}
          iconSize={12}
          size="sm"
        />
        <Box color="dimmed">{metricOption?.icon}</Box>
      </HStack>
    </Tooltip>
  );
};

type Props = {
  segment: SegmentDTO;
  data?: CompanyList;
  isLoading?: boolean;
  isFetching?: boolean;
};

export const CompaniesTable = ({
  segment,
  data,
  isLoading,
  isFetching,
}: Props) => {
  const { currentEnv } = useAuthContext();

  const navigate = useNavigate();

  const { retainedColor } = useStarsFunnelColors();

  const { data: features = [] } = useFeatureNamesData();

  const tableState = useCompaniesTableState(segment);
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const columnHelper = createSortableColumnHelper<
    CompanyListItem,
    CompaniesSortBy
  >();
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Name",
        cell: ({ row }) => {
          return (
            <CompanyDisplay
              company={row.original}
              fontSize="md"
              fontWeight="medium"
            />
          );
        },
      }),
      columnHelper.accessor("id", {
        header: "ID",
        cell: (cell) => <Text fontSize="sm">{cell.getValue()}</Text>,
      }),
      columnHelper.accessor("firstSeen", {
        header: "First seen",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("lastSeen", {
        header: "Last seen",
        sortDescFirst: true,
        cell: (cell) => <DataCell type="date" value={cell.getValue()} />,
      }),
      columnHelper.accessor("feedbackCount", {
        header: "Feedback",
        cell: (cell) => (
          <DataCell type="number" value={cell.getValue().toString()} compact />
        ),
      }),
      columnHelper.accessor("userCount", {
        header: "Users",
        cell: (cell) => (
          <DataCell type="number" value={cell.getValue().toString()} compact />
        ),
      }),
      // unfortunately we need to type this manually as it can't be inferred
      ...tableState.columnStates.flatMap<ColumnDef<CompanyListItem, any>>(
        (columnState) => {
          const [key, id, metric] = columnState.id.split(".");
          if (key !== "featureMetrics") return [];
          const feature = features?.find((f) => f.id === id);
          if (!feature) return [];

          if (metric === "funnelStep") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.funnelStep`, {
                id: `featureMetrics.${id}.funnelStep`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="funnelStep" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "eventCount") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.eventCount`, {
                id: `featureMetrics.${id}.eventCount`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="number" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "firstUsed") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.firstUsed`, {
                id: `featureMetrics.${id}.firstUsed`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="date" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "lastUsed") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.lastUsed`, {
                id: `featureMetrics.${id}.lastUsed`,
                sortDescFirst: true,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => (
                  <DataCell type="date" value={cell.getValue()} />
                ),
              }),
            ];
          }

          if (metric === "frequency") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.frequency`, {
                id: `featureMetrics.${id}.frequency`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => {
                  const value = cell.getValue();
                  return value ? (
                    <FrequencyProgress
                      filledColor={retainedColor}
                      frequency={value}
                    />
                  ) : (
                    <NotAvailableCell />
                  );
                },
              }),
            ];
          }

          if (metric === "satisfaction") {
            return [
              columnHelper.accessor(`featureMetrics.${id}.satisfaction`, {
                id: `featureMetrics.${id}.satisfaction`,
                maxSize: 200,
                header: () => (
                  <MetricHeader feature={feature} metric={metric} />
                ),
                meta: {
                  isRemovable: true,
                },
                cell: (cell) => {
                  const value = cell.getValue();
                  return value ? (
                    <FeedbackSatisfactionIcon colored={true} value={value} />
                  ) : (
                    <NotAvailableCell />
                  );
                },
              }),
            ];
          }

          return [];
        },
      ),
      ...tableState.columnStates.flatMap((columnState) => {
        if (!columnState.id.startsWith("attributes.")) return [];
        const id = columnState.id.replace("attributes.", "");
        return [
          columnHelper.accessor(`attributes.${id}`, {
            id: `attributes.${id}`,
            maxSize: 200,
            header: () => (
              <Tooltip label={id} openDelay={300} hasArrow>
                <HStack spacing={1}>
                  <Box color="dimmed">
                    <RiBracesLine size={12} />
                  </Box>
                  <Text isTruncated>{id}</Text>
                </HStack>
              </Tooltip>
            ),
            meta: {
              isRemovable: true,
            },
            cell: (cell) => (
              <DataCell
                type={data?.metadata.attributeTypes[id] ?? "string"}
                value={cell.getValue()}
              />
            ),
          }),
        ];
      }),
    ],
    [
      columnHelper,
      tableState.columnStates,
      features,
      retainedColor,
      data?.metadata.attributeTypes,
    ],
  );

  const companies = data?.data || [];

  return (
    <DataTable
      _toolbarLeft={{ wrap: "wrap", alignSelf: "flex-start" }}
      _toolbarRight={{ wrap: "wrap", flex: "1 1 min-content" }}
      columnCustomizerFooter={
        <VStack align="flex-start" p={2}>
          <AddColumnPopover
            columnStates={tableState.columnStates}
            setColumnStates={tableState.onColumnStatesChange}
          />
        </VStack>
      }
      columns={columns}
      data={companies}
      defaultColumns={defaultCompanyColumns}
      emptyState={
        <CompaniesEmptyState isLoading={isLoading} segment={segment} />
      }
      isFetching={isFetching}
      meta={data?.metadata}
      pageSize={20}
      searchPlaceholder="Search by name or ID"
      tableId="companies"
      toolbarLeftActions={<SegmentFilters segment={segment} />}
      totalCount={data?.totalCount ?? 0}
      variant="clickable"
      canCustomize
      canPaginate
      canSearch
      canSort
      hasPersistedState
      onRowClick={(row, e) => {
        const url = FeatureCompanyUrl(currentEnv!, row.original.id);
        e.preventDefault();

        linkClickModifierHandler(url, () => {
          navigate(FeatureCompanyUrl(currentEnv!, row.original.id));
        })(e);
      }}
      {...safeTableProps}
    />
  );
};
