import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { RiAddLine } from "react-icons/ri";
import { Link as RouterLink, Outlet } from "react-router-dom";
import { Button, ButtonGroup } from "@chakra-ui/react";

import { NewFeedbackUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import HeaderLayout from "@/common/components/HeaderLayout";
import { spanToScore } from "@/common/components/SatisfactionFilter";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { FeedbackTable } from "@/feedback/components/FeedbackTable";
import { useFeedbackData } from "@/feedback/data/useFeedbackData";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

export default function Feedback() {
  const { currentEnv } = useAuthContext();

  const {
    sorting,
    pageIndex,
    satisfactionFilter,
    featureFilter,
    companyFilter,
    funnelStepsFilter,
    segmentFilter,
  } = useFeedbackTableState();

  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useFeedbackData({
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    pageIndex,
    // Filters
    satisfaction: spanToScore(satisfactionFilter),
    featureId: featureFilter,
    companyId: companyFilter,
    funnelSteps: funnelStepsFilter,
    segmentId: segmentFilter,
  });

  useEffect(() => {
    segmentAnalytics.page("Feedback");
  }, []);

  return (
    <>
      <HeaderLayout
        actions={
          <ButtonGroup spacing={4}>
            <Button
              as={RouterLink}
              leftIcon={<RiAddLine size={16} />}
              size="sm"
              to={NewFeedbackUrl(currentEnv!, {
                stars: funnelStepsFilter,
                satisfaction: satisfactionFilter,
                feature: featureFilter,
                company: companyFilter,
              })}
              variant="outline"
            >
              New feedback
            </Button>
          </ButtonGroup>
        }
        title="Feedback"
        borderless
      >
        <>
          <Helmet>
            <title>Feedback</title>
          </Helmet>
          <FeedbackTable
            data={data}
            isFetching={isFetching}
            isLoading={isLoading}
          />
        </>
      </HeaderLayout>
      <Outlet />
    </>
  );
}
