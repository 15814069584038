import {
  FormControl,
  FormLabel,
  Switch,
  SwitchProps,
  Text,
} from "@chakra-ui/react";

import InfoIconTooltip from "@/common/components/InfoIconTooltip";
import { useTargetingParam } from "@/feature/hooks/useTargetingParam";

type Props = SwitchProps;

export const UseTargetingSwitch = (props: Props) => {
  const [useTargeting, setUseTargeting] = useTargetingParam(true);

  return (
    <FormControl alignItems="center" display="flex" gap={2} w="auto">
      <Switch
        id="apply-access-rules"
        isChecked={useTargeting}
        size="sm"
        onChange={() => {
          setUseTargeting(!useTargeting);
        }}
        {...props}
      />
      <FormLabel
        alignItems="center"
        display="inline-flex"
        gap={1}
        htmlFor="apply-access-rules"
        mr="0"
        pb="0"
      >
        <Text fontSize="sm" fontWeight="normal">
          Apply access rules
        </Text>
        <InfoIconTooltip text="If this is enabled, the widget data will be filtered by the access rules, otherwise data for all companies is shown." />
      </FormLabel>
    </FormControl>
  );
};
