import { RiSlackFill } from "react-icons/ri";
import { Text } from "@chakra-ui/react";
import { chakra, ResponsiveValue, useColorModeValue } from "@chakra-ui/system";

import { SlackChannel } from "@bucketco/shared/slackConnectionAPI";

import { useAuthContext } from "@/auth/contexts/authContext";
import SlackHashLogo from "@/common/assets/slack-hash-logo.svg?react";

const SlackHashIcon = chakra(SlackHashLogo);

type SlackNotificationStatusProps = {
  enabled: boolean;
  slackChannel: SlackChannel | null;
  size:
    | ResponsiveValue<(string & object) | "sm" | "md" | "lg" | "xs" | "2xs">
    | undefined;
};

export function SlackNotificationStatus({
  enabled,
  slackChannel,
  size = "sm",
}: SlackNotificationStatusProps) {
  const { currentApp, currentEnv } = useAuthContext();

  const defaultSlackChannel =
    currentEnv?.slackChannel ||
    currentApp?.environments.find((env) => env.isProduction)?.slackChannel;

  const inactiveColor = useColorModeValue("gray.400", "gray.600");
  if (!enabled || (!slackChannel && !defaultSlackChannel)) {
    return (
      <Text color={inactiveColor} fontSize={size} fontWeight="normal">
        <RiSlackFill size="1rem" />{" "}
        <Text as="span">Update notifications are not configured</Text>
      </Text>
    );
  }

  return (
    <Text fontSize={size} fontWeight="normal">
      <SlackHashIcon
        boxSize={3}
        display="inline-block"
        verticalAlign="middle"
      />{" "}
      <Text as="span">Updates will be shared in</Text>{" "}
      <Text as="span" display="inline-block" fontWeight="semibold">
        {slackChannel?.name ?? defaultSlackChannel?.name}
      </Text>
    </Text>
  );
}
