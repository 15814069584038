import { useMemo } from "react";
import { RiChat1Line } from "react-icons/ri";
import { Link, Link as RouterLink, useParams } from "react-router-dom";
import { Badge, Box, Button, Flex, HStack, Text } from "@chakra-ui/react";

import {
  FeedbackDTO,
  FeedbackList,
  FeedbackListSortBy,
} from "@bucketco/shared/feedbackAPI";
import { FeatureSettingsUrl, FeedbackUrl } from "@bucketco/shared/urls";

import { useAuthContext } from "@/auth/contexts/authContext";
import AutoFeedbackSurveysIcon from "@/common/assets/auto-feedback-surveys-icon.svg?react";
import CompanyDisplay from "@/common/components/CompanyDisplay";
import { DataTable } from "@/common/components/DataTable/DataTable";
import EmptyState from "@/common/components/EmptyState";
import ExpandableTextCell from "@/common/components/ExpandableTextCell";
import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import NotAvailableCell from "@/common/components/NotAvailableCell";
import { spanToScore } from "@/common/components/SatisfactionFilter";
import { SectionHeading } from "@/common/components/SectionHeading";
import TimestampCell from "@/common/components/TimestampCell";
import { useSafeDataTableStateProps } from "@/common/hooks/useDataTableParamState";
import { createSortableColumnHelper } from "@/common/types/reactTableHelpers";
import FeatureSideBarWrapper from "@/feature/components/FeatureSidebarWrapper";
import SatisfactionHistogram from "@/feature/components/SatisfactionHistogram";
import { SetupInstructions } from "@/feature/components/SetupInstructions";
import { starsFunnelStateDescriptions } from "@/feature/data/starsFunnelDescriptions";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { cellVerticalAdjust } from "@/feedback/components/FeedbackTable";
import SatisfactionCell from "@/feedback/components/SatisfactionCell";
import { SatisfactionTimeline } from "@/feedback/components/SatisfactionTimeline";
import { useFeedbackData } from "@/feedback/data/useFeedbackData";
import { useFeedbackTableState } from "@/feedback/hooks/useFeedbackTableState";

export default function FeatureFeedback() {
  const { featureId } = useParams();

  return (
    <FeatureSideBarWrapper>
      <Flex direction="column" flexGrow={1} h="full" px={6} py={4}>
        <SetupInstructions featureId={featureId} usecase="feedback" />
        <Flex direction="column" gap={6} mb={6} w="full">
          <RecentResponses featureId={featureId!} />
          <SatisfactionStatistics featureId={featureId!} />
        </Flex>
      </Flex>
    </FeatureSideBarWrapper>
  );
}

function RecentResponses({ featureId }: { featureId: string }) {
  const { currentEnv } = useAuthContext();
  const {
    sorting,
    pageIndex,
    funnelStepsFilter,
    satisfactionFilter,
    companyFilter,
  } = useFeedbackTableState();

  // We only handle sorting one column for now
  const { id: sortBy, desc: sortDesc } = sorting[0] ?? {};
  const { data, isLoading, isFetching } = useFeedbackData({
    sortBy,
    sortOrder:
      sortDesc === true ? "desc" : sortDesc === false ? "asc" : undefined,
    pageIndex,
    pageSize: 5,
    // Filters
    funnelSteps: funnelStepsFilter,
    satisfaction: spanToScore(satisfactionFilter),
    featureId,
    companyId: companyFilter,
  });

  const { data: feature, isSuccess: featureIsSuccess } =
    useFeatureData(featureId);

  return (
    <Flex direction="column" gap={4}>
      <Flex align="center" direction="row" justify="space-between">
        <HStack>
          <SectionHeading>Latest responses</SectionHeading>
          {isLoading && <LoadingSpinner variant="subtle" />}
        </HStack>
        {!featureIsSuccess ? null : (
          <Link to={FeatureSettingsUrl(currentEnv!, feature, "feedback")}>
            {feature?.autoFeedbackSurveysEnabled ? (
              <Badge colorScheme="brand" size="md" variant="subtle">
                <HStack spacing={1}>
                  <AutoFeedbackSurveysIcon height={16} width={16} />
                  <Text>Auto surveys are enabled</Text>
                </HStack>
              </Badge>
            ) : (
              <Badge colorScheme="gray" size="md" variant="outline">
                <HStack spacing={1}>
                  <AutoFeedbackSurveysIcon height={16} width={16} />
                  <Text>Auto surveys are disabled</Text>
                </HStack>
              </Badge>
            )}
          </Link>
        )}
      </Flex>
      <RecentResponsesTable
        data={data}
        isFetching={isFetching}
        isLoading={isLoading}
      />
      <Box>
        <Button
          as={RouterLink}
          size="sm"
          to={FeedbackUrl(currentEnv!, {
            stars: funnelStepsFilter,
            satisfaction: satisfactionFilter,
            feature: featureId,
            company: companyFilter,
          })}
          variant="outline"
        >
          See all responses
        </Button>
      </Box>
    </Flex>
  );
}

function RecentResponsesTable({
  data,
  isLoading,
  isFetching,
}: {
  data?: FeedbackList;
  isLoading?: boolean;
  isFetching?: boolean;
}) {
  const tableState = useFeedbackTableState();
  // We don't want to pass along invalid props to the data table
  const safeTableProps = useSafeDataTableStateProps(tableState);

  const columnHelper = createSortableColumnHelper<
    FeedbackDTO,
    FeedbackListSortBy
  >();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("score", {
        header: "Score",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== null ? (
                <SatisfactionCell value={value} />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
      columnHelper.accessor("comment", {
        header: "Comment",
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value ? (
                <ExpandableTextCell value={value} />
              ) : (
                <Text color="dimmed">No comment</Text>
              )}
            </Box>
          );
        },
        minSize: 280,
      }),
      columnHelper.accessor("userName", {
        header: "User",
        // enableSorting: false,
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            <Text fontSize="sm" noOfLines={1}>
              {row.original.userName || row.original.userId}
            </Text>
          </Box>
        ),
      }),
      columnHelper.accessor("companyName", {
        header: "Company",
        cell: ({ row }) => (
          <Box {...cellVerticalAdjust}>
            {row.original.companyId ? (
              <CompanyDisplay
                company={{
                  id: row.original.companyId,
                  name: row.original.companyName,
                  avatarUrl: row.original.companyAvatarUrl,
                }}
                link
              />
            ) : (
              <NotAvailableCell />
            )}
          </Box>
        ),
      }),
      columnHelper.accessor("companyFunnelStep", {
        header: "STARS state",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          const description = starsFunnelStateDescriptions.find(
            ({ id }) => id === value,
          );
          return description ? (
            <HStack spacing={2} {...cellVerticalAdjust}>
              {description.visualization}
              <Text>{description.label}</Text>
            </HStack>
          ) : (
            <NotAvailableCell />
          );
        },
      }),
      columnHelper.accessor("timestamp", {
        header: "Received",
        sortDescFirst: true,
        cell: (cell) => {
          const value = cell.getValue();
          return (
            <Box {...cellVerticalAdjust}>
              {value !== undefined ? (
                <TimestampCell value={value} leftAlign />
              ) : (
                <NotAvailableCell />
              )}
            </Box>
          );
        },
      }),
    ];
  }, [columnHelper]);

  const users = data?.data || [];

  return (
    <DataTable
      columns={columns}
      data={users}
      emptyState={
        <EmptyState
          border="1px solid"
          borderColor="appBorder"
          borderRadius="md"
          description="No feature feedback yet"
          icon={<RiChat1Line size="48px" />}
          minH="2xs"
          showContent={!isLoading}
          w="full"
        />
      }
      isFetching={isFetching}
      meta={data?.metadata}
      scrollable={false}
      tableId="feature-feedback"
      totalCount={data?.totalCount ?? 0}
      {...safeTableProps}
    />
  );
}

function SatisfactionStatistics({ featureId }: { featureId: string }) {
  return (
    <Flex direction="column" gap={4}>
      <Flex gap={8} w="full">
        <Flex direction="column" gap={0} w="full">
          <SatisfactionTimeline featureId={featureId} />
        </Flex>
        <Flex direction="column" gap={6} maxW="xs" minW="2xs">
          <Flex align="center" direction="row" minH={8}>
            <SectionHeading>Satisfaction distribution</SectionHeading>
          </Flex>
          <SatisfactionHistogram featureId={featureId} />
        </Flex>
      </Flex>
    </Flex>
  );
}
