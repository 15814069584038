import { useMemo, useState } from "react";
import { ButtonGroup, Flex, HStack } from "@chakra-ui/react";

import { LoadingSpinner } from "@/common/components/LoadingSpinner";
import PeriodPicker, {
  Period,
  periodList,
} from "@/common/components/PeriodPicker";
import RollingTimeWindowPicker, {
  RollingWindowPeriod,
} from "@/common/components/RollingTimeWindowPicker";
import { SectionHeading } from "@/common/components/SectionHeading";
import { useCurrentEnv } from "@/common/hooks/useCurrentEnv";
import { useSearchParam } from "@/common/hooks/useSearchParam";
import { periodToDates } from "@/common/utils/periodToDates";
import SatisfactionAverageTimelineChart from "@/feedback/components/SatisfactionAverageTimelineChart";
import { useFeatureFeedbackTimelineData } from "@/feedback/data/useFeedbackTimelineData";

export function SatisfactionTimeline({
  featureId,
  subsegmentId,
}: {
  featureId: string;
  subsegmentId?: string;
}) {
  const { envId } = useCurrentEnv();

  const [period, setPeriod] = useSearchParam<Period>("period", {
    allowlist: periodList,
    fallback: "past30days",
  });

  const [rollingWindowSize, setRollingWindowSize] =
    useState<RollingWindowPeriod>(7);

  const { startDate, endDate } = useMemo(() => periodToDates(period), [period]);

  const { data = [], isLoading } = useFeatureFeedbackTimelineData({
    envId: envId!,
    startDate: startDate.toDate(),
    endDate: endDate.toDate(),
    rollingWindowSize,
    featureIds: [featureId],
    subsegment: subsegmentId,
  });

  return (
    <>
      <Flex align="center" direction="row" justify="space-between">
        <HStack>
          <SectionHeading>Average satisfaction</SectionHeading>
          {isLoading && <LoadingSpinner variant="subtle" />}
        </HStack>
        <ButtonGroup spacing={2}>
          <PeriodPicker
            value={period}
            onChange={(val) => setPeriod(val, { replace: true })}
          />
          <RollingTimeWindowPicker
            value={rollingWindowSize}
            onChange={(val) => setRollingWindowSize(val)}
          />
        </ButtonGroup>
      </Flex>
      <SatisfactionAverageTimelineChart
        data={data}
        height={200}
        isLoading={isLoading}
      />
    </>
  );
}
