import { Flex, StackProps, Text } from "@chakra-ui/react";

import { highlightStringMatch } from "@/common/components/Autocomplete/AutocompleteSelect";
import { Avatar, AvatarProps } from "@/common/components/Avatar";

type UserName = {
  id: string;
  email?: string | null;
  name?: string | null;
  avatarUrl?: string | null;
};

type Props = StackProps & {
  user: UserName;
  search?: string;
  showId?: boolean;
  showEmail?: boolean;
  onlyFirstName?: boolean;
  _avatar?: AvatarProps;
};

export function UserDisplay({
  user,
  search,
  showId,
  showEmail,
  onlyFirstName,
  _avatar,
  ...rest
}: Props) {
  const emailName = user.email?.split("@")[0];
  const name = user.name ?? emailName ?? user.id;
  const shortName =
    user.name?.split(" ")[0] ?? emailName?.split(" ")[0] ?? user.id;
  const displayName = onlyFirstName ? shortName : name;
  const avatarUrl = user.avatarUrl;

  return (
    <Flex alignItems="center" gap={2} whiteSpace="nowrap" {...rest}>
      <Avatar name={name} src={avatarUrl ?? undefined} {..._avatar} />
      <Text>
        {search ? highlightStringMatch(displayName, search) : displayName}
      </Text>
      {showEmail && user.email ? (
        <Text>
          &lt;
          {search ? highlightStringMatch(user.email, search) : user.email}
          &gt;
        </Text>
      ) : null}
      {showId && user.id ? (
        <Text color="dimmedDisabled">
          ({search ? highlightStringMatch(user.id, search) : user.id})
        </Text>
      ) : null}
    </Flex>
  );
}
