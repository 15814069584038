import { useEffect } from "react";
import { FormProvider, useForm, useFormContext } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ButtonGroup, Flex, Switch, Text } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";

import {
  SlackIntegrationSchema,
  SlackIntegrationType,
} from "@bucketco/shared/featureAPI";

import { SlackChannelAutocompleteSelect } from "@/common/components/Autocomplete/SlackChannelAutocompleteSelect";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { ManagedFormControl } from "@/common/components/Form/ManagedFormControl";
import SlackConnectionGuard from "@/common/components/slack/SlackConnectionGuard";
import { useFormMutationSubmitHandler } from "@/common/hooks/useApiForm";
import { useSlackConnected } from "@/common/hooks/useSlackConnected";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import { useFeatureData } from "@/feature/data/useFeatureData";
import { useFeatureUpdateMutation } from "@/feature/data/useFeatureUpdateMutation";

export function SettingsSlack() {
  useEffect(() => {
    segmentAnalytics.page("Feature Slack Integration");
  }, []);

  return (
    <SlackConnectionGuard productionOnly>
      <SlackForm />
    </SlackConnectionGuard>
  );
}

export function SlackForm() {
  const { featureId } = useParams();
  const { data: feature } = useFeatureData(featureId!);

  const form = useForm<z.input<typeof SlackIntegrationSchema>>({
    resolver: zodResolver(SlackIntegrationSchema),
    mode: "onChange",
    defaultValues: feature?.integrations.slack || {
      feedbackNotification: true,
      targetingRulesChangeNotification: true,
    },
  });

  const updateMutation = useFeatureUpdateMutation(featureId!);
  const submitHandler = useFormMutationSubmitHandler(
    form,
    updateMutation,
    (result) => {
      form.reset(result.integrations.slack);
    },
    {
      prepareVariables: (values) => ({
        id: featureId!,
        integrations: { slack: values },
      }),
    },
  );

  const isSlackConnected = useSlackConnected();

  return (
    <FormProvider {...form}>
      <Flex
        as="form"
        direction={"column"}
        gap={4}
        maxW="compactForm"
        onSubmit={submitHandler}
      >
        <FormSlackIntegration />
        <FormRootError />
        {isSlackConnected && (
          <ButtonGroup>
            <FormSubmitLegacy />
            <FormReset />
          </ButtonGroup>
        )}
      </Flex>
    </FormProvider>
  );
}

export default function FormSlackIntegration() {
  const form = useFormContext<SlackIntegrationType>();
  const isSlackConnected = useSlackConnected();

  return (
    <Flex direction="column" gap={5} maxW="compactForm" w="full">
      <ManagedFormControl
        label="Slack channel"
        name="slackChannel"
        render={({ field }) => (
          <SlackChannelAutocompleteSelect {...field} canClear />
        )}
      />

      <Flex direction="column" w="full">
        <ManagedFormControl
          alignItems="center"
          isDisabled={form.formState.isSubmitting}
          label="Notify on user feedback"
          name="feedbackNotification"
          render={({ field }) => (
            <Flex align="center" justify="flex-end">
              <Switch
                {...field}
                colorScheme="brand"
                isChecked={field.value}
                isDisabled={!isSlackConnected}
              />
            </Flex>
          )}
          horizontal
        />
        <Text color="dimmed" fontSize="sm" maxW="xs">
          An immediate notification when a user leaves feedback on this feature
        </Text>
      </Flex>

      <Flex direction="column" w="full">
        <ManagedFormControl
          alignItems="center"
          isDisabled={form.formState.isSubmitting}
          label="Notify when access rules are updated or rolled back"
          name="targetingRulesChangeNotification"
          render={({ field }) => (
            <Flex align="center" justify="flex-end">
              <Switch
                {...field}
                colorScheme="brand"
                isChecked={field.value}
                isDisabled={!isSlackConnected}
              />
            </Flex>
          )}
          horizontal
        />
        <Text color="dimmed" fontSize="sm" maxW="xs">
          A notification sent when any feature access rules are updated or
          rolled back.
        </Text>
      </Flex>
    </Flex>
  );
}
