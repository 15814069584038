import { FormProvider } from "react-hook-form";
import {
  Badge,
  Box,
  ButtonGroup,
  Divider,
  Flex,
  Link,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";

import {
  PatchOrgArgsSchema,
  PatchOrgArgsType,
} from "@bucketco/shared/organizationAPI";
import {
  KeyFormatPatterns,
  KeyFormats,
} from "@bucketco/shared/utils/keyFormat";

import { useAuthContext } from "@/auth/contexts/authContext";
import FormInput from "@/common/components/Form/FormInput";
import FormReset from "@/common/components/Form/FormReset";
import { FormRootError } from "@/common/components/Form/FormRootError";
import FormSelect from "@/common/components/Form/FormSelect";
import FormSubmitLegacy from "@/common/components/Form/FormSubmitLegacy";
import { commonQueryKeys } from "@/common/data/commonQueryKeys";
import useApiForm from "@/common/hooks/useApiForm";
import api from "@/common/utils/api";
import { segmentAnalytics } from "@/common/utils/segmentAnalytics";
import OrganizationLogoPicker from "@/global-settings/components/OrganizationLogoPicker";

export default function GeneralOrganization() {
  const { currentOrg } = useAuthContext();
  const orgId = currentOrg!.id;

  const toast = useToast();
  const queryClient = useQueryClient();

  const { form, handleSubmit } = useApiForm(
    (data: PatchOrgArgsType) =>
      api
        .patch<"/orgs/:orgId">(`/orgs/${orgId}`, data)
        .then((res) => res.data.org),
    PatchOrgArgsSchema,
    {
      onSuccess: (org) => {
        segmentAnalytics.track("Org Options Updated");
        toast({
          title: "Settings saved",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        queryClient.invalidateQueries({ queryKey: commonQueryKeys.bootstrap });
        form.reset({
          name: org.name,
          logoUrl: org.logoUrl,
          featureKeyFormat: org.featureKeyFormat,
        });
      },
    },
    {
      defaultValues: {
        name: currentOrg?.name,
        logoUrl: currentOrg?.logoUrl,
        featureKeyFormat: currentOrg?.featureKeyFormat,
      },
    },
  );

  const featureKeyFormat = form.watch("featureKeyFormat") ?? "custom";
  return (
    <Flex direction="column" gap={6}>
      <form onSubmit={handleSubmit}>
        <FormProvider {...form}>
          <VStack align="flex-start" maxW="compactForm" spacing={4}>
            <FormInput
              autoComplete="organization"
              label="Organization name"
              name="name"
            />

            <OrganizationLogoPicker />

            <FormSelect
              label="Feature key format"
              name="featureKeyFormat"
              options={KeyFormats.map((format) => ({
                label: KeyFormatPatterns[format].label,
                value: format,
              }))}
            />
            <Text color="dimmed" fontSize="sm">
              {KeyFormatPatterns[featureKeyFormat].description}
            </Text>
            <FormRootError />
            <ButtonGroup>
              <FormSubmitLegacy />
              <FormReset />
            </ButtonGroup>
          </VStack>
        </FormProvider>
      </form>
      <Divider />
      <VStack align="flex-start">
        <Flex direction="column" gap={2}>
          <Box fontWeight="semibold">Data residency</Box>{" "}
          <Box>
            <Badge>{currentOrg?.isGlobal ? "Global" : "Europe"}</Badge>
          </Box>
        </Flex>
        <Box>
          Contact us at{" "}
          <Link href="mailto:hello@bucket.co">hello@bucket.co</Link> to change
          your data residency.
        </Box>
      </VStack>
    </Flex>
  );
}
