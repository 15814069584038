import { ReactNode } from "react";
import { RiErrorWarningLine, RiInformationLine } from "react-icons/ri";
import {
  Badge,
  Box,
  BoxProps,
  Flex,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverContentProps,
  PopoverProps,
  PopoverTrigger,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { PortalWrapper } from "@/common/components/PortalWrapper";

export type InfoIconTooltipProps = PopoverProps & {
  text: ReactNode;
  icon?: ReactNode;
  docsURL?: string;
  renderInPortal?: boolean;
  _content?: PopoverContentProps;
  _icon?: BoxProps;
};

export default function InfoIconTooltip({
  text,
  icon = <RiInformationLine />,
  docsURL,
  renderInPortal = true,
  _content,
  _icon,
  ...rest
}: InfoIconTooltipProps) {
  const tooltipColor = useColorModeValue("gray.400", "gray.600");
  const tooltipHoverColor = useColorModeValue("gray.700", "gray.300");

  return (
    <Popover trigger="hover" variant="tooltip" {...rest}>
      <PopoverTrigger>
        <Box
          _hover={{ color: tooltipHoverColor }}
          as="span"
          color={tooltipColor}
          display="inline-block"
          role="button"
          {..._icon}
        >
          {icon}
        </Box>
      </PopoverTrigger>
      <PortalWrapper usePortal={renderInPortal}>
        <PopoverContent {..._content}>
          <PopoverArrow />
          <PopoverBody fontSize="sm">
            <Box>{text}</Box>
            {docsURL && (
              <Link
                display="block"
                fontSize="sm"
                href={docsURL}
                mt={2}
                target="_blank"
              >
                Learn more
              </Link>
            )}
          </PopoverBody>
        </PopoverContent>
      </PortalWrapper>
    </Popover>
  );
}

type WarningBadgeTooltipProps = Omit<InfoIconTooltipProps, "icon"> & {
  label?: string;
};

export function WarningBadgeTooltip({
  label,
  size = "md",
  ...props
}: WarningBadgeTooltipProps) {
  return (
    <InfoIconTooltip
      {...props}
      icon={
        <Badge colorScheme="orange" size={size} variant="subtle">
          <Flex alignItems="center" gap={1}>
            <RiErrorWarningLine />
            {label && <Text>{label}</Text>}
          </Flex>
        </Badge>
      }
    />
  );
}
